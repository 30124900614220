import Jumbotron from '@/components/core/Jumbotron/Jumbotron';
import { Layout } from '@/components/shared/layouts/Layout/Layout';
import { GetServerSidePropsContext, InferGetServerSidePropsType, NextPage } from 'next';
import ClientOnly from '@/components/shared/utilities/ClientOnly/ClientOnly';

import Section from '@/components/core/Section/Section';

import { createClient } from '@/jsonapi/createClient';
import isRetina from '@/utils/image';

import styles from '../styles/index.module.scss';
import infoBoxStyles from '../assets/stylesheets/components/_infoBox.module.scss';
import jumbotronOverlayStyles from '../assets/stylesheets/components/_jumbotronOverlay.module.scss';

import { getSafeLocale } from '@/utils/locale';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useMemo } from 'react';

import { serializeCollectionResource } from '@/utils/serilize-resource';

import ModalLink from 'next/link';

import { MapProvider } from '@/components/features/map/MapContext';
import { MapStats } from '@/components/shared/map-stats/MapStats';
import { IMuseumResource } from '@/interfaces/IMuseumResource';
import { IPostResource } from '@/interfaces/IPostResource';
import { IStoryResource } from '@/interfaces/IStoryResource';
import { IClusterResource } from '@/interfaces/IClusterResource';
import { INewsResource } from '@/interfaces/INewsResource';

import Map from '@/components/features/map/Map';
import { FilterMapping, HomePostsSection } from '@/components/features/posts/HomePostsSection/HomePostsSection';
import { PinnedNews } from '@/components/features/posts/PinnedNews/PinnedNews';
import { CollectionDocument, IResource, TopLevelPaginationLinks } from '@/interfaces/Jsonapi';
import { useTranslation } from 'next-i18next';
import { NextSeo } from 'next-seo';

function selectRandom<
	TData extends IResource = IResource,
	TMeta extends Record<any, any> = Record<any, any>,
	TLinks extends TopLevelPaginationLinks = TopLevelPaginationLinks
>(document: CollectionDocument<TData, TMeta, TLinks>, count = 2) {
	const indices = [];
	const selectCount = Math.min(count, document.data.length);

	while (indices.length < selectCount) {
		const index = Math.floor(Math.random() * document.data.length);

		if (!indices.includes(index)) {
			indices.push(index);
		}
	}

	return {
		...document,
		data: indices.map((index) => document.data[index]),
	};
}

type HomeProps = InferGetServerSidePropsType<typeof getServerSideProps>;

const HomePage: NextPage<HomeProps> = ({
	currentMuseums,
	upcomingMuseums,
	clusters,
	stories,
	news,
	postsFallbackData,
}) => {
	const { t } = useTranslation();
	const museumsOpenForDonations = useMemo(() => {
		return [...currentMuseums.data, ...upcomingMuseums.data].filter((museum) => museum.openForDonation);
	}, [currentMuseums, upcomingMuseums]);

	return (
		<>
			<NextSeo titleTemplate="%s" title={t('museum-of-broken-relationships-title')} />
			<Layout>
				<MapProvider>
					<Jumbotron
						backgroundImage={isRetina() ? 'images/header_image.jpg' : 'images/header_image.jpg'}
						contentClassName={styles.homeJumbotronContent}
						color={Jumbotron.color.PRIMARY}
						maskColor={news.data.length > 0 ? null : Jumbotron.maskColor.DIMMED}
					>
						<Map museums={currentMuseums.data} clusters={clusters.data} />
					</Jumbotron>
					<div className={jumbotronOverlayStyles.jumbotronOverlay}>
						<div className={jumbotronOverlayStyles.jumbotronOverlayContainer}>
							<div className={jumbotronOverlayStyles.jumbotronOverlayInfoLeft}>
								<ClientOnly>
									<MapStats museums={currentMuseums.data} stories={stories} clusters={clusters.data} />
								</ClientOnly>
							</div>
							<div className={jumbotronOverlayStyles.jumbotronOverlayInfoRight}>
								<div className={infoBoxStyles.infoBox}>
									<div className={infoBoxStyles.infoBoxTitle}>{t('open-call-for-contributions')}</div>
									<ul className={infoBoxStyles.infoBoxList}>
										{(museumsOpenForDonations || []).map((museum) => (
											<ModalLink
												key={museum.id}
												href={{ query: { open: 'contribute', selectedMuseumId: museum.id } }}
												className={infoBoxStyles.infoBoxListItem}
											>
												{museum.title}
											</ModalLink>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</MapProvider>

				{news.data.length > 0 ? (
					<Section>
						<div className={styles.container}>
							<PinnedNews news={news.data} />
						</div>
					</Section>
				) : (
					<Section color="Dimmed" size="Small" />
				)}

				<HomePostsSection fallbackData={postsFallbackData} />
			</Layout>
		</>
	);
};

export const getServerSideProps = async ({ locale, query, req }: GetServerSidePropsContext) => {
	const client = createClient({ locale, cookie: req.headers.cookie });

	const currentMuseumsResponse = await client.findAll<IMuseumResource>('museums', {
		per_page: 1000,
		filters: 'current',
	});

	const upcomingMuseumsResponse = await client.findAll<IMuseumResource>('museums', {
		per_page: 1000,
		filters: 'upcoming',
	});

	const clustersResponse = await client.findAll<IClusterResource>('user/story/cluster', {
		per_page: 1000,
	});

	const postsResponse = await client.findAll<IPostResource>('posts', {
		include: 'favoriter',
		page: 1,
		per_page: 16,
		filters: FilterMapping[query.filter as keyof typeof FilterMapping],
	});

	const storiesResponse = await client.findAll<IStoryResource>('stories', {
		per_page: 1000,
		include: 'favoriter',
	});

	const pinnedNewsResponse = await client.findAll<INewsResource>('news/post', {
		include: 'favoriter',
		per_page: 16,
		filters: 'pinned',
	});

	/**
	 * This is a fix for Devour incorrect handling of collection responses.
	 * Devour is polluting native Array prototype and adding a `meta` and `links` property to it.
	 * Next.js will not serialize those properties and we will lose them on the client side.
	 * You should always use this function to serialize collection responses.
	 */
	const currentMuseums = serializeCollectionResource(currentMuseumsResponse);
	const upcomingMuseums = serializeCollectionResource(upcomingMuseumsResponse);
	const clusters = serializeCollectionResource(clustersResponse);
	const posts = serializeCollectionResource(postsResponse);
	const stories = serializeCollectionResource(storiesResponse);

	const news = selectRandom(serializeCollectionResource(pinnedNewsResponse));

	return {
		props: {
			currentMuseums,
			upcomingMuseums,
			clusters,
			postsFallbackData: [posts],
			stories,
			news,
			...(await serverSideTranslations(getSafeLocale(locale), ['common'])),
		},
	};
};

export default HomePage;
