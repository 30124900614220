import { FC, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import useSWRInfinite from 'swr/infinite';

import Section from '@/components/core/Section/Section';
import HomePosts from '@/components/features/posts/HomePosts/HomePosts';
import SharePanel from '@/components/shared/share-panel/SharePanel';
import { useClient } from '@/components/shared/utilities/AppProvider/AppProvider';
import { extractSWRInfiniteData } from '@/utils/swr/extractSWRInfiniteData';
import { serializeCollectionResource } from '@/utils/serilize-resource';
import { CollectionDocument } from '@/interfaces/Jsonapi';
import { IPostResource } from '@/interfaces/IPostResource';

export interface IHomePostsSectionProps {
	fallbackData: Array<CollectionDocument<IPostResource>>;
}

export const FilterMapping: Record<string, string> = {
	undefined: 'news',
	trending: 'trending',
	collection: 'collection',
	stories: 'stories',
	news: 'news',
};

export const HomePostsSection: FC<IHomePostsSectionProps> = ({ fallbackData }) => {
	const { query } = useRouter();
	const client = useClient();

	const {
		data: posts,
		error,
		setSize,
		size,
	} = useSWRInfinite(
		(pageIndex, previousPageData) => {
			if (previousPageData && !previousPageData?.links?.next) return null; // reached the end

			const pageNumber = pageIndex + 1;

			return [
				'posts',
				{
					include: 'favoriter',
					page: pageNumber,
					per_page: 16,
					filters: FilterMapping[query.filter as keyof typeof FilterMapping],
				},
			] as const;
		},
		async (key) => {
			const data = await client.findAll(...key);

			return serializeCollectionResource(data);
		},
		{
			fallbackData,
		}
	);

	const { items, isLoadingMore, isReachingEnd, meta } = useMemo(
		() => extractSWRInfiniteData(posts, error, size),
		[posts, error, size]
	);

	const onLoadMore = useCallback(() => setSize(size + 1), [setSize, size]);

	return (
		<Section color="Dimmed">
			<HomePosts posts={items} meta={meta} onLoadMore={onLoadMore} hasMore={!isReachingEnd} loading={isLoadingMore} />
			<SharePanel />
		</Section>
	);
};
